.articleWrapper {
  text-align: left;
}

.instructions {
  display: flex;
  flex-direction: column;
  padding: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.08px;
  font-family: 'Source Serif 4', serif;
  text-align: left;
  max-width: 560px;
  margin: 6em auto;
}

.instructions .left {
  width: 200px;
  min-width: 200px;
  margin-bottom: 20px;
}

.instructions h1 {
  font-size: 22px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  margin-top: 0;
  letter-spacing: -0.04px;
}

.instructions b {
  font-weight: 700;
}

.instructions button {
  width: 149px;
  margin: 0 auto;
  padding: 12px 20px;
  font-size: 17px;
}

.literata {
  font-family: 'Literata', serif;
}

.bionic {
  font-family: 'IBM Plex Serif', serif;
}

@media screen and (max-width: 900px) {
  .instructions {
    margin: 48px auto;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .instructions .left {
    margin-bottom: 40px;
  }
}
