.resultsWrapper {
  display: flex;
  width: 100%;
}

.results {
  width: 625px;
  border: 1px solid #E0E3E6;
  margin: 98px auto;
  padding: 40px 32px;
  padding-bottom: 0;
  font-family: 'Inter', sans-serif;
}

.mainHeader {
  display: flex;
  margin-bottom: 25px;
}

.mainHeader h1 {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  /* or 125% */
  letter-spacing: -0.02em;
}

.logo {
  margin-left: auto;
}

.share {
  display: flex;
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-left: 10px;
  cursor: pointer;
}

.share svg {
  width: 25px;
  height: 25px;
  color: #376EF2;
}

.row {
  display: flex;
  border-bottom: 1px solid rgba(40, 49, 59, 0.09);
  padding-bottom: 16px;
  margin-top: 20px;
}

.header {
  display: flex;
  width: 100%;
  max-width: 200px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #616C77;
  flex: 1;
}

.content {
  display: flex;
  flex: 1;
}

.cell {
  display: flex;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #28313B;
  flex: 1;
}

.footer {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #28313B;
  padding: 50px 0;
}

@media screen and (max-width: 700px) {
  .results {
    border: 0;
  }

  .row {
    flex-direction: column;
  }

  .header {
    margin-bottom: 12px;
  }
}
